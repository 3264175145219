@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,400italic,700,700italic");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  color:#383D4C;
  -moz-osx-font-smoothing: grayscale;
}
button,select, span, p, label, input, textarea
{
  font-size: 14px !important;
}
.NewInputField {
 font-size: 16px !important;
}
.customSpan{
  font-size: 12px !important;
}
.customSpan2{
  font-size: 13px !important;
}
/* label span:last-child{
  color:#383D4C !important
} */
.intl-tel-input input[type=tel]{
  min-height: 30px;
  width: 100%;
  font-size: 16px !important;
}
/* .phoneTextColor .intl-tel-input input{
color:rgba(0, 0, 0, 0.87);

} */
.selectInputColor{
  color:#383D4C !important;
  width:100%
  }
  .selectInputColor p{
  color:#383D4C !important;
  
  }
.intl-tel-input{
  width: 100%;
  font-size: 16px !important;
}
.labelWrap{
  font-family: 'Roboto', sans-serif;
  max-width: 100%;
  font-size: 16px !important;
}
.labelBody{
  min-width: 270px; 
  color: #383D4C;
  word-break:break-all;
  float:left;
}
.safariIndex .MuiPaper-root{
  z-index: 1000;
}
/* label + .MuiInput-formControl{
  margin-top: 10px !important;
} */
label+.MuiInputBase-root.MuiInput-root{
margin-top: 22px !important;
}
.customLabel{
  float:right; 
  /* margin-left: 50px;  */
  color: #383D4C;
  text-transform:uppercase;
  padding-left: 10px;
}
.formatedOptions span{
  font-size:9px !important;
  padding:3px 7px;
  border-radius: 8px;
}
.formatedOptions .onLabel{
  background:#d7d7d7;
}
.formatedOptions{
  display: block;
}
.intl-tel-input input[type=tel]::placeholder {
color:#c9d3df !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  margin: 0; 
}
textarea:disabled, input:disabled{
  color:#383D4C61 !important

}
textarea {
  padding:0px !important
}
.fieldBorderColor fieldset{
  border-color: #D32F2F !important;
}
.fieldBorderColor label{ color: #D32F2F !important;}
.fieldBorderColor p{ color: #D32F2F !important;}
#logBook-print-footer{
  display: none;
}
@media (max-width: 696px){
.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody{
  max-width: calc(100%) !important;
  margin: 20% 4.5% !important;
  }
}
@media (max-width:720px){
  .MuiDialog-paperScrollBody{
    display: block !important;
    max-width: inherit !important;
    margin: 15px !important;
  }
}
@media (max-width:960px){
  .iti-mobile .intl-tel-input.iti-container {
    top: 269px !important;
    bottom: 49px !important;
    left: 30px !important;
    right: 25px !important;
    position: absolute !important;
    width: 30%;
  }
}
@media (max-width:600px){
  .iti-mobile .intl-tel-input.iti-container {
    top: 339px !important;
    bottom: 49px !important;
    left: 30px !important;
    right: 25px !important;
    position: absolute !important;
    width: 50%;
  }
}
@media print {
  body * {
    visibility: hidden;
  }
  #print-graph, #print-graph * {
    visibility: visible;
  }
  .graphPrint div div {
    overflow: visible !important;
  }
  #print-graph .pad_35, #print-graph .header{
    padding: 15px 0 0 45px !important;
    max-width: 750px !important;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 1;
  }
  #print-footer, #print-footer * {
    visibility: visible;
  }
  .footerHide{
    display: none !important;
  }
  #logBook-print-footer{
    display: block !important;
  }
  /* logBook Print Css */
  @-moz-document url-prefix(){
    .graphTable{
      display: block !important;
    }
    .borderTable, .borderTable *{
      visibility: visible !important;
      position: static !important;
    }
  }
}
.react-player__preview{
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.react-player__shadow{
  background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%) !important;
}
.SelectWithLabel .MuiFormControl-root {
  width : 100% !important;
}
.SelectPadding1 .MuiOutlinedInput-input {
padding: 10px 4px !important;
}
.adminNavSelect .MuiInputBase-input div:first-child {
  flex-wrap: inherit !important;
}
/* .MuiInputLabel-formControl {
	position: relative !important;
} */
/* .selected-dial-code{
	padding-right: 20px!important;
} */
.closeIcnDirectionBased .react-datepicker__close-icon{
	left:0;
  	right: inherit;
}
.MuiTextField-root{
	width:100%
}
input[type=number] {
	-moz-appearance: textfield;
}
.accrdnCstmStyl.MuiExpansionPanelSummary-root.Mui-disabled{
opacity:1 !important ;
}
.disabledBtn.Mui-disabled{
  color: rgba(0, 0, 0, 0.26) !important;
  border: none
}
.MuiListItem-root{
white-space: unset !important;
}
.providerPhone .intl-tel-input input{
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 3px;
    border-width:1px;
 }
.highcharts-range-selector-buttons .highcharts-label text{
font-size: 18px !important;
fill:rgba(0, 0, 0, 0.54) !important;
}
/* .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
padding-left: 8px !important;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    display: flex !important;
}
.intl-tel-input .flag-container .arrow {
    font-size: 10px !important;
} */
.MuiTypography-root.PrivatePickersMonth-root.Mui-selected {
    color: #fff !important;
}
.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled{
-webkit-text-fill-color:inherit !important;
}
.MuiInputBase-input.MuiInput-input.Mui-disabled{
-webkit-text-fill-color:inherit !important;
}
/* .MuiInputBase-root.MuiInput-root.Mui-error:after {
    border-bottom-color: #D32F2F !important;
} */
.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled {
  color:rgba(56, 61, 76, 0.38) !important;
}
.tableHeader span button { max-width: 165px ; white-space: initial;text-align: left;}
.icnColor{color: #B4B4B4}
.zindexMenuList{z-index: 2;}
.WrapText .MuiTextField-root .MuiInputBase-input div{
  flex-wrap: inherit !important;
}
.WrapTextpad .MuiTextField-root .MuiInputBase-input div{
 padding:8px 4px;
}
.WrapTextpad .MuiTextField-root .MuiInputBase-input p{
  padding-bottom:0px;
  padding-left:4px;
 }
 .WrapTextpadTop .MuiTextField-root .MuiInputBase-input div{
  padding-bottom:3px;
  padding-top:3px;
  padding-left:6px;
 }
 legend span{
  padding-left: 0px !important;
  padding-right: 0px !important;
 }
 .selectHeight .MuiOutlinedInput-root{
  height:56px;
 }
 .tickIcon .fa-duotone-group .fa-secondary{
  color: #c8e99c
 }
 .orFont span{
  font-size: 12px !important;
 }
 .padBrand .SingleValue{
  width: 90%
 }
 .errorFont{
  font-size: 12px !important;
 }
 .afterSelect .MuiChip-label{
  font-size: 16px !important
 }
 .MuiFormLabel-root{
  font-size: 16px !important;
  color:#383D4C;
 }
  .btn {
  text-decoration: none !important;
}
.tablecss  {
  border: 1px solid;
  border-color: #EAECF0;
  border-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  box-shadow: inherit !important;
}
.statusNewLabel .MuiChip-label {
  padding-left: 0px!important;
  padding-right: 0px !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 13px !important;
  line-height: 18px;
  letter-spacing: 0.16px;
}
 .highcharts-root { padding:  0px  24px}
/* .Mui-error .MuiOutlinedInput-notchedOutline legend span{
  color:#D32F2F;
 } */
